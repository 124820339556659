import { all, fork } from 'redux-saga/effects'

import adminLogsSagas from './adminlogs-sagas'
import authSagas from './auth-sagas'
import authenticationLogSagas from './authlog-sagas'
import branchSagas from './branch-sagas'
import chatsSagas from './chats-sagas'
import checklistSagas from './checklist-sagas'
import checklistResourcesSagas from './checklistresource-sagas'
import checklistTypesSagas from './checklisttypes-sagas'
import classificationSagas from './classification-sagas'
import companySagas from './company-sagas'
import contactSagas from './contact-sagas'
import contactGroupSagas from './contactgroup-sagas'
import customMetadataSagas from './custom-metadata-sagas'
import deviceSagas from './device-sagas'
import documentSagas from './document-sagas'
import editorialSagas from './editorial-sagas'
import entityLockSagas from './entitylock-sagas'
import facebookSagas from './facebook-sagas'
import facebookCategorySagas from './facebookcategory-sagas'
import facebookPageSagas from './facebookpage-sagas'
import feedLaneSagas from './feedlane-sagas'
import googleSagas from './google-sagas'
import groupSagas from './group-sagas'
import guidelineSagas from './guideline-sagas'
import iitemplateSagas from './iitemplate-sagas'
import indexSagas from './index-sagas'
import institutionSagas from './institution-sagas'
import ldapSagas from './ldap-sagas'
import licenseSagas from './license-sagas'
import mailSagas from './mail-sagas'
import mediaSagas from './media-sagas'
import nominatimSagas from './nominatim-sagas'
import newsSagas from './news-sagas'
import posts from './post-sagas'
import previewSagas from './preview-sagas'
import programSagas from './program-sagas'
import reportSagas from './report-sagas'
import reportCategorySagas from './reportcategory-sagas'
import reportEventSagas from './reportevent-sagas'
import reportageSagas from './reportage-sagas'
import reutersSagas from './reuters-sagas'
import roundSagas from './round-sagas'
import rssCategorySagas from './rsscategory-sagas'
import rssFeedSagas from './rssfeed-sagas'
import rundownSagas from './rundown-sagas'
import rundownTemplateSagas from './rundowntemplate-sagas'
import segmentSagas from './segment-sagas'
import souvSagas from './souv-sagas'
import storySagas from './story-sagas'
import storyTemplateSagas from './storytemplate-sagas'
import streamingSagas from './streaming-sagas'
import twitterSagas from './twitter-sagas'
import twitterCategorySagas from './twittercategory-sagas'
import twitterListSagas from './twitterlist-sagas'
import twitterUserSagas from './twitteruser-sagas'
import typeSagas from './type-sagas'
import userSagas from './user-sagas'
import vehicleSagas from './vehicle-sagas'
import wordpressSagas from './wordpress-sagas'

function* rootSaga() {
  yield all([
    fork(adminLogsSagas),
    fork(authSagas),
    fork(authenticationLogSagas),
    fork(branchSagas),
    fork(chatsSagas),
    fork(checklistSagas),
    fork(checklistResourcesSagas),
    fork(checklistTypesSagas),
    fork(classificationSagas),
    fork(companySagas),
    fork(contactSagas),
    fork(contactGroupSagas),
    fork(customMetadataSagas),
    fork(deviceSagas),
    fork(documentSagas),
    fork(editorialSagas),
    fork(entityLockSagas),
    fork(facebookSagas),
    fork(facebookCategorySagas),
    fork(facebookPageSagas),
    fork(feedLaneSagas),
    fork(googleSagas),
    fork(groupSagas),
    fork(guidelineSagas),
    fork(iitemplateSagas),
    fork(indexSagas),
    fork(institutionSagas),
    fork(ldapSagas),
    fork(licenseSagas),
    fork(mailSagas),
    fork(mediaSagas),
    fork(newsSagas),
    fork(nominatimSagas),
    fork(posts),
    fork(previewSagas),
    fork(programSagas),
    fork(reportSagas),
    fork(reportageSagas),
    fork(reportCategorySagas),
    fork(reportEventSagas),
    fork(reutersSagas),
    fork(roundSagas),
    fork(rssCategorySagas),
    fork(rssFeedSagas),
    fork(rundownSagas),
    fork(rundownTemplateSagas),
    fork(segmentSagas),
    fork(souvSagas),
    fork(storySagas),
    fork(storyTemplateSagas),
    fork(streamingSagas),
    fork(twitterSagas),
    fork(twitterCategorySagas),
    fork(twitterListSagas),
    fork(twitterUserSagas),
    fork(typeSagas),
    fork(userSagas),
    fork(vehicleSagas),
    fork(wordpressSagas),
  ])
}

export default rootSaga
