import { call, put, takeLatest } from 'redux-saga/effects'

import { reutersApi as api } from '@anews/api'
import { ReutersCompleteItem, ReutersConfig } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  ReutersActions,
  ReutersActionMap as ActionMap,
  ReutersActionType as ActionType,
} from '../actions/reuters-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions
const {
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  loadItemSuccess,
  loadItemFailure,
} = ReutersActions

/* Watchers */

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as ReutersConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: ActionMap<ActionType.UPDATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as ReutersConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* loadItemSaga(action: ActionMap<ActionType.LOAD_ITEM_REQUEST>): Generator {
  try {
    const item = yield call(api.getItem, action.item.uri)
    yield put(loadItemSuccess(item as ReutersCompleteItem))
  } catch (error) {
    yield put(loadItemFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_ITEM_REQUEST, loadItemSaga)
  },
])
